import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

function withAuth<T extends object>(WrappedComponent: React.ComponentType<T>): React.FC<T> {
    return function ProtectedComponent(props: T) {
        const { isLoggedIn, isLoading } = useAuth();

        if (isLoading) {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                       <CircularProgress />
                      <CircularProgress />
                   </div>;
        }

        if (!isLoggedIn) {
            return <Navigate to="/login" replace />;
        }

        return <WrappedComponent {...props} />;
    };
}

export default withAuth;
