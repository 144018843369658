import React, { useState } from 'react';
import { Typography, Link, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Box } from '@mui/material';
import './index.css'; 
import { useTranslation } from '../../LanguageContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface OrgPageProps {
    selectedOrg?: any;
    setSelectedOrg?: React.Dispatch<React.SetStateAction<any>>;
}

const OrgPage: React.FC<OrgPageProps> = ({ selectedOrg, setSelectedOrg }) => {
    // States for collapsing/expanding sections
    const [generalInfoExpanded, setGeneralInfoExpanded] = useState(true);
    const [givingMetricsExpanded, setGivingMetricsExpanded] = useState(true);
    const [manageUsersExpanded, setManageUsersExpanded] = useState(true);
    const [inviteUsersExpanded, setInviteUsersExpanded] = useState(true);

    const [editingGeneralInfo, setEditingGeneralInfo] = useState(false);

    const generalInfoKeys = ['organizationLogo', 'taxID', 'legalAddress', 'fiscalYear', 'sector', 'crm'];

    const { translate } = useTranslation();
    const [organizationData, setOrganizationData] = useState<any>(selectedOrg);


    const handleEditGeneralInfo = () => {
        setEditingGeneralInfo(true);
    };

    const handleCancelEdit = () => {
        setEditingGeneralInfo(false);
    };

    const handleSaveGeneralInfo = () => {
        // Logic to save updated general information
        setEditingGeneralInfo(false);
    };

    return (
        selectedOrg ? (
        <div className="org-page-container">
            {/* Page title */}
            <Typography variant="h1" className="big-title">
                {translate('OrgPage', 'pageTitle')} {organizationData?.name}
            </Typography>

            {/* Watch the tutorial */}
            <Typography variant="body1" className="tutorial-link">
                <Link href="#">{translate('OrgPage', 'watchTutorial')}</Link>
            </Typography>

            {/* General Information title */}
            <Typography variant="h2" className="small-title" style={{ display: 'flex', alignItems: 'center' }}>
                <span className="dropdown-arrow" onClick={() => setGeneralInfoExpanded(!generalInfoExpanded)}>
                    {generalInfoExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </span>
                {translate('OrgPage', 'generalInfoTitle')}
                {!editingGeneralInfo && generalInfoExpanded && (
                    <button className="edit-buttons" onClick={handleEditGeneralInfo}>Edit</button>
                )}
                {editingGeneralInfo && (
                    <div className="edit-buttons">
                        <button onClick={handleSaveGeneralInfo}>Save Section</button>
                        <button onClick={handleCancelEdit}>Cancel</button>
                    </div>
                )}
            </Typography>



            {/* General Information Details */}
            {generalInfoExpanded && (
                <Paper elevation={3} className="info-container">
                    <Grid container spacing={4} className="info-grid">
                        {Object.entries(organizationData).map(([key, value]) => (
                            <React.Fragment key={key}>
                                {/* Check if the key is included in generalInfoKeys array */}
                                {generalInfoKeys.includes(key) && (
                                    <>
                                        <Grid item xs={12} sm={3}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', key)}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            {editingGeneralInfo ? (
                                                <div>
                                                    {key === 'organizationLogo' ? (
                                                        <div>
                                                            <Link href="#">Upload file</Link> {/* Replace with file upload */}
                                                            <Typography variant="body2" style={{ color: '#A9A9A9', fontStyle: 'italic' }}>Current file: {value as string}</Typography>
                                                        </div>
                                                    ) : (
                                                        <input type="text" value={value as string} onChange={(e) => setOrganizationData({ ...organizationData, [key]: e.target.value })} />
                                                    )}
                                                </div>
                                            ) : (
                                                <Typography variant="body1">{value as string}</Typography>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Paper>
            )}

            {/* Giving Metrics title */}
            <Typography variant="h2" className="small-title">
                <span className="dropdown-arrow" onClick={() => setGivingMetricsExpanded(!givingMetricsExpanded)}>
                    {givingMetricsExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </span>
                {translate('OrgPage', 'givingMetricsTitle')}
            </Typography>

            {/* Giving Metrics Details */}
            {givingMetricsExpanded && (
            <Paper elevation={3} className="info-container">
                <Grid container spacing={4} className="info-grid">
                <Grid item xs={12} sm={3}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'averageMajorGiftAmount')}</Typography></Grid>
                <Grid item xs={12} sm={9}><Typography variant="body1">{organizationData?.averageMajorGiftAmount}</Typography></Grid>
                <Grid item xs={12} sm={3}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'dollarAmountToQualifyAsMajorGift')}</Typography></Grid>
                <Grid item xs={12} sm={9}><Typography variant="body1">{organizationData?.dollarAmountToQualifyAsMajorGift}</Typography></Grid>
                <Grid item xs={12} sm={3}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'dollarAmountToQualifyAsMidLevelGift')}</Typography></Grid>
                <Grid item xs={12} sm={9}><Typography variant="body1">{organizationData?.dollarAmountToQualifyAsMidLevelGift}</Typography></Grid>
                <Grid item xs={12} sm={3}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'averageLegacyGiftWhenRealized')}</Typography></Grid>
                <Grid item xs={12} sm={9}><Typography variant="body1">{organizationData?.averageLegacyGiftWhenRealized}</Typography></Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'givingLevels')}</Typography>
                    <Typography variant="body1" style={{ fontStyle: 'italic', marginTop: '8px' }}>{translate('OrgPage', 'givingLevelsLimit')}</Typography>
                </Grid>
                    <Grid item xs={12} sm={9}>
                        <Table className='giving-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'givingLevelsNameCol')}</Typography></TableCell>
                                    <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'givingLevelsGivingLevelCol')}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{translate('OrgPage', 'givingLevel1')}</TableCell>
                                    <TableCell>$0 - $50,000</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate('OrgPage', 'givingLevel2')}</TableCell>
                                    <TableCell>$51,000 - $100,000</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{translate('OrgPage', 'givingLevel3')}</TableCell>
                                    <TableCell>$100,000 - $500,000</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'legacySociety')}</Typography></Grid>
                    <Grid item xs={12} sm={9}><Typography variant="body1">{organizationData?.legacySociety}</Typography></Grid>
                    <Grid item xs={12} sm={3}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'charitableGiftAnnuities')}</Typography></Grid>
                    <Grid item xs={12} sm={9}>
                        <Table className='giving-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'minimumDonorAge')}</Typography></TableCell>
                                    <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'minimumGiftAmount')}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>65</TableCell>
                                    <TableCell>$150,000</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
            )}

            {/* Manage Users title */}
            <Typography variant="h2" className="small-title">
                <span className="dropdown-arrow" onClick={() => setManageUsersExpanded(!manageUsersExpanded)}>
                    {manageUsersExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </span>
                {translate('OrgPage', 'manageUsersTitle')}
            </Typography>

            {/* Manage Users Details */}
            {manageUsersExpanded && (
            <Paper elevation={3} className="info-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('OrgPage', 'userCol')}</TableCell>
                            <TableCell>{translate('OrgPage', 'emailCol')}</TableCell>
                            <TableCell>{translate('OrgPage', 'statusCol')}</TableCell>
                            <TableCell>{translate('OrgPage', 'permissionsCol')}</TableCell>
                            <TableCell>{translate('OrgPage', 'actionsCol')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Here we would map through user data to create rows */}
                        {/* Example Row */}
                        <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>user@example.com</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Admin</TableCell>
                            <TableCell>
                                {/* Example Button */}
                                <button>Remove Access</button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
            )}

            {/* Invite Users title */}
            <Typography variant="h2" className="small-title">
                <span className="dropdown-arrow" onClick={() => setInviteUsersExpanded(!inviteUsersExpanded)}>
                    {inviteUsersExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </span>
                {translate('OrgPage', 'inviteUsersTitle')}
            </Typography>

            {/* Invite Users Details */}
            {inviteUsersExpanded && (
                <Paper elevation={3} className="invite-users-container">
                    <div style={{ padding: '30px' }}>
                        <Grid container spacing={2}>
                            {/* First Name */}
                            <Grid item xs={12} sm={8}>
                                <label htmlFor="firstName" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'firstName')}</label>
                                <input type="text" id="firstName" style={{ width: '100%', marginTop: '8px' }} />
                            </Grid>
                            {/* Last Name */}
                            <Grid item xs={12} sm={8}>
                                <label htmlFor="lastName" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'lastName')}</label>
                                <input type="text" id="lastName" style={{ width: '100%', marginTop: '8px' }} />
                            </Grid>
                            {/* Email */}
                            <Grid item xs={12} sm={8}>
                                <label htmlFor="email" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'email')}</label>
                                <input type="email" id="email" style={{ width: '100%', marginTop: '8px' }} />
                            </Grid>
                            {/* Permissions */}
                            <Grid item xs={12}>
                                <label style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'permissions')}</label>
                                <div>
                                    <input type="radio" id="admin" name="permissions" value="admin" />
                                    <label htmlFor="admin">{translate('OrgPage', 'admin')}</label>
                                </div>
                                <div>
                                    <input type="radio" id="user" name="permissions" value="user" />
                                    <label htmlFor="user">{translate('OrgPage', 'user')}</label>
                                </div>
                            </Grid>
                            {/* Message */}
                            <Grid item xs={12}>
                                <label htmlFor="message" style={{ fontWeight: 'bold' }}>{translate('OrgPage', 'message')}</label>
                                <textarea id="message" rows={4} style={{ width: '100%', marginTop: '8px' }} />
                            </Grid>
                            {/* Send Invite button */}
                            <Grid item xs={12}>
                                <button style={{ marginTop: '16px' }}>{translate('OrgPage', 'sendInvite')}</button>
                            </Grid>
                        </Grid>
                    </div>
                    {/* Additional information */}
                    <div style={{ padding: '16px', color: '#555555', fontStyle: 'italic' }}>
                        {translate('OrgPage', 'additionalInfo')}
                    </div>
                </Paper>
            )}
        </div>) :
            <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                }}
              >
                <CircularProgress />
            </Box>

    );
};

export default OrgPage;
