import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import CircleIcon from '@mui/icons-material/Circle';
import { useImportCSVContext } from '../contexts/ImportCSVContext.js';

const ConfirmImport: React.FC = () => {
  const { 
    counts, 
    suspectedDuplicateIDs, 
    skipSuspectedDuplicateIDs, 
    suspectedExistingIDs, 
    skipSuspectedExistingIDs, 
    suspectedDuplicateSkipAll, 
    suspectedExistingDoForAll, 
    unsubscribedSkipAll, 
    updatingSkipAll, 
    existingSkipAll, 
    cleanSkipAll 
  } = useImportCSVContext();

  // Calculate the effective count for suspected duplicates
  const suspectedDuplicateTotal = suspectedDuplicateIDs.flat().length;
  const suspectedDuplicateImportCount = suspectedDuplicateTotal - skipSuspectedDuplicateIDs.length;

  // Calculate the effective count for suspected existing
  const suspectedExistingTotal = suspectedExistingIDs.length;
  const suspectedExistingImportCount = suspectedExistingTotal - skipSuspectedExistingIDs.length;

  // Define categories and their corresponding colors
  const categories = [
    { label: 'Incomplete Records', color: '#B50D0D' },  // Red
    { label: 'Suspected Duplicate Records', color: '#FF9800', count: suspectedDuplicateImportCount, show: suspectedDuplicateIDs.length > 0 }, // Orange
    { label: 'Suspected Existing Records', color: '#FFC107', count: suspectedExistingImportCount, show: suspectedExistingIDs.length > 0 }, // Yellow
    { label: 'Unsubscribed Records', color: '#AB47BC' },  // Purple
    { label: 'Updating Records', color: '#29B6F6' },  // Light Blue
    { label: 'Existing Records', color: '#1976D2' },  // Deep Blue
    { label: 'Clean Records', color: '#46900C' },  // Green
  ];
  

  // Calculate ready to import categories
  const readyToImport = categories.filter(category => {
    if (!category.show && category.show !== undefined) return false; // Exclude categories not meant to be shown

    let count = category.label === 'Suspected Duplicate Records'
      ? suspectedDuplicateImportCount
      : category.label === 'Suspected Existing Records'
      ? suspectedExistingImportCount
      : counts[category.label] || 0;

    if (count === 0) return false; // Exclude categories with a count of 0

    switch (category.label) {
      case 'Suspected Duplicate Records':
        return !suspectedDuplicateSkipAll;
      case 'Suspected Existing Records':
        return !suspectedExistingDoForAll;
      case 'Unsubscribed Records':
        return !unsubscribedSkipAll;
      case 'Updating Records':
        return !updatingSkipAll;
      case 'Existing Records':
        return !existingSkipAll;
      case 'Clean Records':
        return !cleanSkipAll;
      default:
        return true;
    }
  });

  // Calculate skip import categories
  const skipImport = categories.filter(category => {
    if (!category.show && category.show !== undefined) return false; // Exclude categories not meant to be shown

    let count = category.label === 'Suspected Duplicate Records'
      ? suspectedDuplicateTotal
      : category.label === 'Suspected Existing Records'
      ? suspectedExistingTotal
      : counts[category.label] || 0;

    // If Suspected Duplicate or Existing counts are 0 after skips, move them to the skip section
    if (category.label === 'Suspected Duplicate Records' && suspectedDuplicateImportCount === 0) {
      return true;
    }

    if (category.label === 'Suspected Existing Records' && suspectedExistingImportCount === 0) {
      return true;
    }

    return count > 0 && !readyToImport.includes(category);
  });

  // Prepare data for the doughnut chart
  const chartData = [
    ...readyToImport.map(category => ({ id: category.label, value: category.count || counts[category.label], label: category.label, color: category.color })),
    ...skipImport.map(category => ({ id: category.label, value: category.count || counts[category.label], label: category.label, color: category.color })),
  ];

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" fontFamily="'Inter', sans-serif">
      {/* Top Explanation Text */}
      <Typography variant="body1" gutterBottom style={{ color: '#33475B', marginBottom: '40px' }}>
        Below is a summary of your data. By clicking the “IMPORT” button you are confirming that you have reviewed all of the data and are now ready to import the list.
      </Typography>
      
      {/* Container for Chart and Legend */}
      <Box display="flex" alignItems="flex-start" width="100%">
        {/* Doughnut Chart on the Left */}
        <Box>
          <PieChart
            series={[
              {
                data: chartData,
                innerRadius: '35%',  // Adjusted to reduce the size of the hollow center
                outerRadius: '90%',  // Increased overall size of the chart
                paddingAngle: 3,
                cornerRadius: 3,
              }
            ]}
            width={400}  // Increased chart width
            height={400}  // Increased chart height
            colors={chartData.map(item => item.color)} // Map the colors to the segments
            aria-label="Import Data Chart"
            // Disable the built-in legend
            slotProps={{ legend: { hidden: true } }}
          />
        </Box>

        {/* Summary Text on the Right */}
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={4}>
          <Box display="flex" flexDirection="row">
            {readyToImport.length > 0 && (
              <Box mr={4}>
                <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" fontFamily="'Inter', sans-serif" color="#33475B">
                  Ready to Import:
                </Typography>
                <List>
                  {readyToImport.map(category => (
                    <ListItem key={category.label} sx={{ padding: 0, marginBottom: '8px', alignItems: 'center' }}>
                      <ListItemIcon sx={{ minWidth: '30px', color: category.color, display: 'flex', alignItems: 'center' }}>
                        <CircleIcon sx={{ color: category.color, verticalAlign: 'middle' }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={`${category.count || counts[category.label]} ${category.label}`} 
                        sx={{ color: category.color, margin: 0.5, fontFamily: "'Inter', sans-serif" }} 
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {skipImport.length > 0 && (
              <Box>
                <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" fontFamily="'Inter', sans-serif" color="#33475B">
                  Skip Import:
                </Typography>
                <List>
                  {skipImport.map(category => (
                    <ListItem key={category.label} sx={{ padding: 0, marginBottom: '8px', alignItems: 'center' }}>
                      <ListItemIcon sx={{ minWidth: '30px', color: category.color, display: 'flex', alignItems: 'center' }}>
                        <CircleIcon sx={{ color: category.color, verticalAlign: 'middle' }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={`${category.label === 'Suspected Duplicate Records' || category.label === 'Suspected Existing Records' ? suspectedDuplicateTotal || suspectedExistingTotal : counts[category.label]} ${category.label}`} 
                        sx={{ color: category.color, margin: 0.5, fontFamily: "'Inter', sans-serif" }} 
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmImport;
