export const apiClient = async (url: string, options: RequestInit = {}): Promise<Response> => {
    const token = sessionStorage.getItem('jwtToken');
    const headers = {
        'Content-Type': 'application/json',
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    };
    const updatedOptions: RequestInit = {
        ...options,
        headers: {
            ...headers,
            ...options.headers,
        },
    };

    const response = await fetch(url, updatedOptions);
    if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || `Error: ${response.statusText}`);
    }
    return response;
};