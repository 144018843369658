import React from 'react';
import { Card, CardActionArea, CardContent, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SupportersList from '../../components/view_supporters/SupportersMap';

interface SupporterOptionProps {
  title: string;
  path: string;
}

const SupporterOption: React.FC<SupporterOptionProps> = ({ title, path }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card variant="outlined" sx={{ height: '300px', cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      }, }}
      onClick={handleNavigate}
      >
        <CardActionArea style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Supporters: React.FC = () => {
  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <SupporterOption title="Import Supporters" path="/import-supporters" />
      <SupporterOption title="Import History" path="/import-history" />
      <SupporterOption title="View Supporters" path="/view-supporters" />
    </Grid>
  );
};

export default Supporters;
