import React, { createContext, useState, useContext } from 'react';

import OrgPageTrans from './views/org/translations.json';

const translations = {
  OrgPage: OrgPageTrans,
  // Add other components' translations here
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // For the time being set the translation language here

  const translate = (component, key) => {
    return translations[component]?.[language]?.[key] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useTranslation = () => {
  return useContext(LanguageContext);
};

