import React, { useState, useEffect } from 'react';
import {
  Button,
  MenuItem,
  Typography,
  FormControl,
  Grid,
  SelectChangeEvent,
  Paper,
  Select,
  styled,
  SelectProps,
  Box,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FooterButtons from './FooterButtons';
import SupporterCard from './view_supporters/SupporterCard'; // Import your vertical SupporterCard component

interface FieldMappingProps {
  csvFields: string[];
  dbFields: string[];
  onSubmit: (mappings: Record<string, string>) => void;
  onBack: (step: number) => void;
  cancel: () => void;
  activeStep: number;
  headers: string[];
  setHeaders: React.Dispatch<React.SetStateAction<string[]>>;
  sampleData: Record<string, string[]>;
  requiredFields: string[];
  onConfirmMapping: () => void;
}

const CustomArrow = styled(ArrowDropDownIcon)({
  color: '#7C90A3',
  position: 'absolute',
  right: '10px',
  top: '50% !important',
  transform: 'translateY(-50%) !important',
  scale: '1.5 !important',
});

const Dropdown = styled((props: SelectProps<string>) => (
  <Select {...props} IconComponent={CustomArrow} />
))(({ theme }) => ({
  borderRadius: '5px',
  border: '1px solid #7C90A3',
  backgroundColor: '#ECF4F9',
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    paddingLeft: '10px',
    paddingRight: '48px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '7px',
    paddingBottom: '7px',
  },
}));

const autoMatchCsvField = (dbField: string, csvFields: string[]) => {
  const lowerCsvFields = csvFields.map((field) => field.toLowerCase());

  if (dbField.toLowerCase() === 'first_name' && lowerCsvFields.includes('first name')) {
    return csvFields[lowerCsvFields.indexOf('first name')];
  }
  if (dbField.toLowerCase() === 'last_name' && lowerCsvFields.includes('last name')) {
    return csvFields[lowerCsvFields.indexOf('last name')];
  }
  if (dbField.toLowerCase() === 'email' && lowerCsvFields.includes('email')) {
    return csvFields[lowerCsvFields.indexOf('email')];
  }
  // Add more field matching logic if necessary

  return '';
};

const FieldMapping: React.FC<FieldMappingProps> = ({
  csvFields,
  dbFields,
  onSubmit,
  onBack,
  cancel,
  activeStep,
  headers,
  setHeaders,
  sampleData,
  requiredFields,
  onConfirmMapping,
}) => {
  const [mappings, setMappings] = useState<Record<string, string>>({});
  const [showSampleData, setShowSampleData] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  // Auto-match fields on initial render
  useEffect(() => {
    const initialMappings: Record<string, string> = {};
    dbFields.forEach((dbField) => {
      const matchedCsvField = autoMatchCsvField(dbField, csvFields);
      if (matchedCsvField) {
        initialMappings[dbField] = matchedCsvField;
      }
    });
    setMappings(initialMappings);
  }, [csvFields, dbFields]);

  const handleChange = (dbField: string) => (event: SelectChangeEvent<string>) => {
    setMappings({
      ...mappings,
      [dbField]: event.target.value as string,
    });
  };

  const validateRequiredFields = () => {
    const allRequiredMapped = requiredFields.every((field) => mappings[field] && mappings[field].trim() !== '');
    setIsErrorVisible(!allRequiredMapped);
    return allRequiredMapped;
  };

  // Prepare mapped sample data for supporter cards
  const mappedSupporters = Array.from({ length: 4 }).map((_, index) => ({
    first_name: sampleData[mappings['first_name']]?.[index] || '',
    last_name: sampleData[mappings['last_name']]?.[index] || '',
    email: sampleData[mappings['email']]?.[index] || '',
    phone_number: sampleData[mappings['phone_number']] && sampleData[mappings['phone_number']][index] !== undefined
    ? sampleData[mappings['phone_number']][index]?.toString() 
    : '',
    image: sampleData[mappings['image']]?.[index] || '',
  }));

  return (
    <Paper
      elevation={0}
      sx={{
        margin: '20px 0',
        padding: '20px',
        borderRadius: '5px',
        border: '2px solid #C8D7E1',
        background: '#FFF',
      }}
    >
      <Grid container spacing={2} justifyContent="center" marginTop={0.1} marginBottom={4}>
        {!showSampleData ? (
          dbFields.map((field) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              key={field}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <Typography sx={{ width: '70%', fontFamily: 'Inter', display: 'flex', alignItems: 'center' }}>
                {field}
                {requiredFields.includes(field) && (
                  <Typography component="span" sx={{ color: '#BB4E4E', fontStyle: 'italic', marginLeft: '10px' }}>
                    (required)
                  </Typography>
                )}
              </Typography>
              <FormControl fullWidth margin="normal" sx={{ width: '70%' }}>
                <Dropdown value={mappings[field] || ''} onChange={handleChange(field)} label="">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {csvFields.map((csvField) => (
                    <MenuItem key={csvField} value={csvField}>
                      {csvField}
                    </MenuItem>
                  ))}
                </Dropdown>
              </FormControl>
            </Grid>
          ))
        ) : (
          // Display a single row of four mapped supporter cards
          <Grid container spacing={1} justifyContent="center" sx={{marginX: 3, mt: 2}}>
            {mappedSupporters.map((supporter, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SupporterCard person={supporter} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0px',
          mt: 1,
          width: '100%',
        }}
      >
        <Typography
          color="#BB4E4E"
          sx={{
            visibility: isErrorVisible ? 'visible' : 'hidden',
            height: isErrorVisible ? 'auto' : '0px',
            width: '100%',
            textAlign: 'center',
            lineHeight: '0px',
            fontSize: '.95rem',
          }}
        >
          {isErrorVisible ? 'All required fields must be mapped.' : ' '}
        </Typography>
        <FooterButtons
          onClickCancel={() => {
            if (showSampleData) {
              setShowSampleData(!showSampleData);
            } else {
              cancel();
            }
          }}
          onClickConfirm={() => {
            if (validateRequiredFields()) {
              setShowSampleData(!showSampleData);
              if (showSampleData) {
                onSubmit(mappings);
              }
            }
          }}
          cancelLabel="Cancel"
          confirmLabel={showSampleData ? 'Confirm' : 'Submit'}
        />
      </Box>
    </Paper>
  );
};

export default FieldMapping;
