import { Box, Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Footer: React.FC = () => {
    const { isLoggedIn, user } = useAuth();

    return (
        !user && !isLoggedIn ? null :
        <Grid container justifyContent="center" alignItems="center" style={{ padding: '16px' }} direction="column">
            <Grid item>
                <img src="logo-basic-160x24.png" alt="MarketSmart logo" />
            </Grid>
            <Grid item>
                <Box>
                    <p>
                        © {new Date().getFullYear()}{" "}
                        <Link 
                            to="https://imarketsmart.com" 
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                        >
                            <Box 
                                component="span" 
                                sx={{
                                    color: '#447eb0', 
                                    cursor: 'pointer',
                                    "&:hover": {
                                        color: '#2f5679',
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                MarketSmart
                            </Box>
                        </Link>, All Rights Reserved
                    </p>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Footer;
