import React, { useEffect } from 'react';
import { Typography, Link, Grid, Paper } from '@mui/material';
import './index.css';
import toast from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';


const UserPage: React.FC = () => {
    const { user } = useAuth();

    // If the user is not available, handle this scenario appropriately
    useEffect(() => {
        if (!user) {
            toast.error('No user data found. Please log in.', {
                style: {
                    backgroundColor: '#f44336',
                    color: '#ffffff',
                },
                iconTheme: {
                    primary: '#ffffff',
                    secondary: '#f44336',
                },
                position: 'top-center',
            });
        }
    }, [user]);

    return (
        <div className="user-page-container">
            {/* Page title */}
            <Typography variant="h1" className="big-title">
                My Profile
            </Typography>

            {/* Watch the tutorial */}
            <Typography variant="body1" className="tutorial-link">
                <Link href="#">Watch the tutorial</Link>
            </Typography>

            {/* User Profile title */}
            <Typography variant="h2" className="small-title">
                User Profile
            </Typography>

            {/* User Profile Details */}
            <Paper elevation={3} className="info-container">
                <Grid container spacing={4} className="info-grid">
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>First Name:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.firstName}</Typography></Grid>

                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Last Name:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.lastName}</Typography></Grid>

                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Email:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.email}</Typography></Grid>

                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Phone Number:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.phoneNumber || 'Not Specified'}</Typography></Grid>

                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Platform Role:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.platformRole}</Typography></Grid>

                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Organization Role:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.organizationRole}</Typography></Grid>

                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Change Password:</Typography></Grid>
                    <Grid item xs={12} sm={10}><button>Change Password</button></Grid>
                </Grid>
            </Paper>
            {/* <Paper elevation={3} className="info-container">
                <Grid container spacing={4} className="info-grid">
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>First Name:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.firstName}</Typography></Grid>
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Last Name:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.lastName}</Typography></Grid>
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Email:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.email}</Typography></Grid>
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Permissions:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.permissions || 'Not Specified'}</Typography></Grid>
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Your Title:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.title || 'Not Specified'}</Typography></Grid>
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Change Password:</Typography></Grid>
                    <Grid item xs={12} sm={10}><button>Change Password</button></Grid>
                    <Grid item xs={12} sm={2}><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Email Notifications:</Typography></Grid>
                    <Grid item xs={12} sm={10}><Typography variant="body1">{user?.emailNotifications || 'Not Specified'}</Typography></Grid>


                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>What are your primary roles at [ORG NAME]?</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <ul>
                            {user?.primaryRoles.split(',').map((role: string, index: number) => ( // Split the roles by comma, will likely need to change depending on how the data is stored
                                <li key={index}>{role.trim()}</li>
                            ))}
                        </ul>
                    </Grid>

           
                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>What leadership goals are most important to you?</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <ul>
                            {user?.leadershipGoals.split(',').map((goal: string, index: number) => (
                                <li key={index}>{goal.trim()}</li>
                            ))}
                        </ul>
                    </Grid>


                    <Grid item xs={12} sm={3}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>What fundraising goals are most important to you?</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <ul>
                            {user?.fundraisingGoals.split(',').map((goal: string, index: number) => (
                                <li key={index}>{goal.trim()}</li>
                            ))}
                        </ul>
                    </Grid>
                </Grid>
            </Paper> */}
        </div>
    );
};

export default UserPage;
