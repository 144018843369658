// src/components/GoogleAPIProvider.tsx
import React, { createContext, useContext } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getGoogleMapsKey } from '../api/utils';

interface GoogleAPIContextProps {
  isLoaded: boolean;
  loadError: Error | undefined;
}

const GoogleAPIContext = createContext<GoogleAPIContextProps>({
  isLoaded: false,
  loadError: undefined,
});

export const useGoogleAPI = () => useContext(GoogleAPIContext);

const GoogleAPIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [apiKey, setApiKey] = useState<string | null>(null);

  // Fetch the Google Maps API key from your backend
  useEffect(() => {
    getGoogleMapsKey()  
    .then((response) => {
      console.log('Google Maps API key:', response);
      setApiKey(response);
    });
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey ?? '',
  });

  return (
    <GoogleAPIContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleAPIContext.Provider>
  );
};

export default GoogleAPIProvider;