import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, Grid } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

interface LayoutProps {
  children?: React.ReactNode;
  selectedOrg?: any;
  setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
  handleOrgSelect: (org: any) => void;
  handleClose: () => void;
  anchorEl?: HTMLElement | null;
  avatarEl?: HTMLElement | null;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setAvatarEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const Layout: React.FC<LayoutProps> = ({ children, selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect }) => {
  const { isLoggedIn, user } = useAuth();
  return (
    <>
      <CssBaseline />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{ height: '100%', minHeight: '90vh' }}
      >
        <Grid item>
          <Header selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} handleOrgSelect={handleOrgSelect} handleClose={handleClose} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} />
        </Grid>
        <Grid item style={{ flex: 1, padding: '64px 5px' }}>
          {children}
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
