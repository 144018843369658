import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Button,
  Typography,
  Pagination,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ViewSwitchIconList from '../../../components/icons/view_supporters/ViewSwitchIconList.svg';
import ViewSwitchIconGrid from '../../../components/icons/view_supporters/ViewSwitchIconGrid.svg';
import MapIcon from '../../../components/icons/view_supporters/MapIcon.svg';
import FilterIcon from '../../../components/icons/view_supporters/FilterIcon.svg';
import SupporterCard from '../../../components/view_supporters/SupporterCard';
import SupporterCardHorizontal from '../../../components/view_supporters/SupporterCardHorizontal';
import SupportersTable from '../../../components/view_supporters/SupportersTable';
import SupportersMap from '../../../components/view_supporters/SupportersMap';
import UnsubscribeIcon from '../../../components/icons/view_supporters/Unsubscribe.svg';
import ResubscribeIcon from '../../../components/icons/view_supporters/Resubscribe.svg';
import SaveToListIcon from '../../../components/icons/view_supporters/SaveToList.svg';
import MarkAsDeceasedIcon from '../../../components/icons/view_supporters/MarkAsDeceased.svg';
import UnmarkAsDeceasedIcon from '../../../components/icons/view_supporters/Un-markAsDeceased.svg';
import AssignGiftOfficerIcon from '../../../components/icons/view_supporters/AssignGiftOfficer.svg';
import AssignEmailSignerIcon from '../../../components/icons/view_supporters/AssignEmailSigner.svg';
import RemoveFromListIcon from '../../../components/icons/view_supporters/RemoveFromList.svg';
import BulkActionsIcon from '../../../components/icons/view_supporters/BulkActions.svg';
import ConfirmBulkActionDialog from '../../../components/view_supporters/ConfirmBulkActionDialog';

import { updateSupportersWithAction, createSupporterList, updateSupporterList } from '../../../api/supporterService';

interface Supporters {
  salutation?: string | null;
  assigned_gift_officer_id?: number | null;
  unsubscribed?: boolean | null;
  external_id?: string | null;
  group_id?: number | null;
  org_id?: number | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  image?: string | null;
  version?: number;
  is_head?: boolean;
  id: number;
  created_at?: string;
  created_by?: string | null;
  advancement_stage?: string | null;
  engagementScore?: number;
  considerationStage?: string;
  lat?: number;
  lng?: number;
}

const sampleSupporters: Supporters[] = [
  { id: 720002, email: "dkray@hotmail.com", salutation: "Mr.", first_name: "DK", last_name: "Ray", address: "10433 SE 24th Place", city: "Bellevue", state: "WA", zip_code: "98004", phone_number: "5127405368", engagementScore: 370, considerationStage: "Possibly consider" },
  { id: 720003, email: "pjorge@yahoo.com", salutation: "Mr.", first_name: "Paul", last_name: "Jorge", address: "4010 Davila Drive", city: "Dallas", state: "TX", zip_code: "75220", phone_number: "2143957629", engagementScore: 275, considerationStage: "Somewhat likely" },
  { id: 3, email: "agmather@gmail.com", salutation: "Ms.", first_name: "Abbey", last_name: "Mather", address: "129 Columbia Heights", city: "Brooklyn", state: "NY", zip_code: "11201", phone_number: "7182432406", engagementScore: 125, considerationStage: "" },
  { id: 4, email: "kggilli@aol.com", salutation: "Mrs.", first_name: "Karen", last_name: "Gillings", address: "5301 Arbustos Court NE", city: "Albuquerque", state: "NM", zip_code: "87111", phone_number: "5057100374", engagementScore: 205, considerationStage: "" },
  { id: 5, email: "indacon@satx.rr.com", salutation: "Mr.", first_name: "Stephen", last_name: "Marceau", address: "215 Tomahawk Trail", city: "San Antonio", state: "TX", zip_code: "78232", phone_number: "2104941221", engagementScore: 25, considerationStage: "Major donor" },
  { id: 6, email: "carolynbronston@att.net", salutation: "Mrs.", first_name: "Carolyn", last_name: "Bronston", address: "7912 Hollytree Drive", city: "Tyler", state: "TX", zip_code: "75703-0904", phone_number: "7138768393", engagementScore: 520, considerationStage: "Major donor" },
  { id: 7, email: "brad@greenblum.com", salutation: "Mr.", first_name: "Jon", last_name: "Greenblum", address: "2925 Westlake Cv", city: "Austin", state: "TX", zip_code: "78746", phone_number: "5126106600", engagementScore: 495, considerationStage: "Somewhat likely" },
  { id: 8, email: "phyllis_s@rothgeb.net", salutation: "Mrs.", first_name: "Phyllis", last_name: "Schenkkan", address: "4100 Jackson Avenue", city: "Austin", state: "TX", zip_code: "78731", phone_number: "5124677125", engagementScore: 505, considerationStage: "Major donor" }
];

const SupportersView: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDialogOpen, setDialogOpen] = useState(false); // To control the dialog
  const [selectedAction, setSelectedAction] = useState(''); // To store selected action
  const [isListView, setIsListView] = useState(true);
  const [isMapSelected, setIsMapSelected] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(3);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedSupporter, setSelectedSupporter] = useState<Supporters | null>(null);
  const [centerMap, setCenterMap] = useState({ lat: 39.8283, lng: -98.5795 }); // Default to the center of the US
  const [zoom, setZoom] = useState(3);
  const [searchQuery, setSearchQuery] = useState('');
  const [allSupportersSelected, setAllSupportersSelected] = useState<number[]>([]);
  const isBulkActionsOpen = Boolean(anchorEl);
  const [selectedList, setSelectedList] = useState<any>(null); // State for selected list, has all information from the list table
  const [selectedOfficer, setSelectedOfficer] = useState(''); // State for selected gift officer
  const [selectedSigner, setSelectedSigner] = useState(''); // State for selected email signer

  const handleBulkActionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkActionsClose = () => {
    setAnchorEl(null);
  };

  // Conditionally enable the button based on whether supporters are selected
  const isBulkActionsEnabled = allSupportersSelected.length > 0;

  // List of bulk actions
  const bulkActions = [
    { label: "Unsubscribe", icon: UnsubscribeIcon },
    { label: "Resubscribe", icon: ResubscribeIcon },
    { label: "Save to List", icon: SaveToListIcon },
    { label: "Mark as Deceased", icon: MarkAsDeceasedIcon },
    { label: "Un-mark as Deceased", icon: UnmarkAsDeceasedIcon },
    { label: "Assign Gift Officer", icon: AssignGiftOfficerIcon },
    { label: "Assign Email Signer", icon: AssignEmailSignerIcon },
    { label: "Remove from List", icon: RemoveFromListIcon },
  ];

  // Open dialog with selected action
  const handleBulkActionSelect = (action: string) => {
    setSelectedAction(action); // Set the selected action
    setDialogOpen(true); // Open the dialog
    handleBulkActionsClose(); // Close the dropdown menu
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = async () => { // Add 'async' here
    try {
      setDialogOpen(false);
      let response;
  
      const actionKeyMapping: { [key: string]: string } = {
        'Unsubscribe': 'unsubscribe',
        'Resubscribe': 'resubscribe',
        'Mark as Deceased': 'mark_as_deceased',
        'Un-mark as Deceased': 'unmark_as_deceased',
        'Assign Gift Officer': 'assign_gift_officer',
        'Assign Email Signer': 'assign_email_signer',
      };
  
      if (selectedAction in actionKeyMapping) {
        const actionKey = actionKeyMapping[selectedAction];
        const actionValue = true; // Hardcoded since the backend expects this format
        const actionUpdate = { [actionKey]: actionValue };
  
        response = await updateSupportersWithAction(actionUpdate, allSupportersSelected);
      } else if (selectedAction === 'Save to List' || selectedAction === 'Remove from List') {
        // Use the selectedList directly
        response = await updateSupporterList(selectedList.id, allSupportersSelected, selectedAction === 'Remove from List');
      }

    } catch (error) {
      console.error(`Error performing ${selectedAction}:`, error);
    }
  };


  const handleViewSwitch = () => {
    setIsListView(!isListView);
  };

  const handleMapClick = () => {
    setIsMapSelected(!isMapSelected); // Toggle map view on and off
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleSupporterClick = (supporter: Supporters) => {
    setSelectedSupporter(supporter);

    // The updated logic to ensure zooming in correctly
    if (supporter.lat !== undefined && supporter.lng !== undefined) {
      setCenterMap({ lat: supporter.lat, lng: supporter.lng });
      setZoom(10); // Adjust the zoom level to focus on the selected supporter
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const cardWidth = 350;
    const cardMargin = 16;
    const containerPadding = 32;
    const availableWidth = windowWidth - containerPadding;

    if (isMapSelected) {
      setCardsPerPage(2); // Only 2 cards per page in Map View
    } else {
      const maxCardsPerRow = Math.floor((availableWidth + cardMargin) / (cardWidth + cardMargin * 5));
      setCardsPerPage(maxCardsPerRow);
    }
  }, [windowWidth, isMapSelected]);

  // Filter supporters based on the search query
  const filteredSupporters = sampleSupporters.filter((supporter) => {
    const fullName = `${supporter.first_name.toLowerCase()} ${supporter.last_name.toLowerCase()}`;
    return (
      fullName.includes(searchQuery) || 
      supporter.email.toLowerCase().includes(searchQuery)
    );
  });

  const paginatedSupporters = filteredSupporters.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  const totalPages = Math.ceil(filteredSupporters.length / cardsPerPage);

  return (
    <Box sx={{ padding: 2, width: '100%', margin: '0 auto' }}>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Supporters
      </Typography>
      
      {/* Top Bar */}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft={-1}>
        {/* Left side: View switcher and search bar */}
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton onClick={handleViewSwitch} disableRipple>
            <img
              src={isListView ? ViewSwitchIconGrid : ViewSwitchIconList}
              alt="View switch"
              width={70}
              height={70}
            />
          </IconButton>
          <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 350,
              height: 35,
              borderRadius: '5px',
              border: '1px solid #6C8EA5',
              background: 'rgba(108, 142, 165, 0.08)',
              boxShadow: 'none',
              padding: '2px 4px',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by name or email"
              inputProps={{ 'aria-label': 'search by name or email' }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>

        {/* Right side: Map and Filter buttons */}
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="outlined"
            onClick={handleMapClick}
            startIcon={<img src={MapIcon} alt="Map view" />}
            sx={{
              backgroundColor: isMapSelected ? 'rgba(108, 142, 165, 0.42)' : 'rgba(108, 142, 165, 0.17)',
              color: '#406074',
              borderColor: '#6C8EA5',
              '&:hover': { backgroundColor: 'rgba(108, 142, 165, 0.42)' },
            }}
          >
            Map
          </Button>
          <Button
            variant="outlined"
            onClick={() => setIsFilterSelected(!isFilterSelected)}
            startIcon={<img src={FilterIcon} alt="Filter options" />}
            sx={{
              backgroundColor: isFilterSelected ? 'rgba(108, 142, 165, 0.42)' : 'rgba(108, 142, 165, 0.17)',
              color: '#406074',
              borderColor: '#6C8EA5',
              '&:hover': { backgroundColor: 'rgba(108, 142, 165, 0.42)' },
            }}
          >
            Filter
          </Button>
        </Box>
      </Box>
          
      {/* Bulk Actions */}
      <Box display="flex" alignItems="center" gap={2} sx={{ marginBottom: 2 }}>
        <Typography variant="body1" sx={{ color: '#406074' }}>
          {allSupportersSelected.length} selected
        </Typography>
        
        <Box
          component="span"
          sx={{
            height: '24px',
            width: '1px',
            backgroundColor: '#406074',
          }}
        />

        {/* Bulk Actions Button with Icon */}
        <Button
          disableRipple
          disableElevation
          onClick={handleBulkActionsClick}
          disabled={!isBulkActionsEnabled}
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#406074',
            textTransform: 'none',
            fontWeight: 'bold',
            padding: '0',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <img src={BulkActionsIcon} alt="Bulk Actions" width={16} height={16} style={{ marginRight: '8px' }} />
          Bulk Actions
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={isBulkActionsOpen}
          onClose={handleBulkActionsClose}
          PaperProps={{
            elevation: 0,
            sx: {
              maxWidth: 220,
              border: '2px solid #C8D7E1',
              borderRadius: '8px',
              boxShadow: 'none',
            },
          }}
        >
          {bulkActions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={() => handleBulkActionSelect(action.label)} // Trigger dialog
              disableRipple
            >
              <img src={action.icon} alt={action.label} width={16} height={16} style={{ marginRight: '8px' }} />
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* Confirm Bulk Action Dialog */}
      <ConfirmBulkActionDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        actionType={selectedAction}
        selectedSupporterCount={allSupportersSelected.length}
        selectedList={selectedList} // Pass selectedList as a prop
        setSelectedList={setSelectedList} // Pass setSelectedList function as a prop
        onConfirm={handleDialogConfirm}
      />



      {/* Main Content Area */}
      <Grid container spacing={2}>
        {/* Adjust Grid layout based on map presence */}
        <Grid item xs={isMapSelected ? 6 : 12}>
          {isListView ? (
            <SupportersTable supporters={filteredSupporters} handleSupporterClick={handleSupporterClick} onSelectSupporters={setAllSupportersSelected} />
          ) : (
            <>
              {isMapSelected ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  {paginatedSupporters.slice(0, 2).map((supporter, index) => ( // Limit to two horizontal cards
                    <Box key={index} sx={{ width: '100%' }}>
                      <SupporterCardHorizontal person={supporter} />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box display="flex" justifyContent="center" flexWrap="nowrap" overflow="hidden">
                  {paginatedSupporters.map((supporter, index) => (
                    <Box key={index} sx={{ width: 350, margin: 1 }}>
                      <SupporterCard person={supporter} />
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
        </Grid>

        {isMapSelected && (
          <Grid item xs={6}>
            <SupportersMap
              supporters={sampleSupporters}
              selectedSupporter={selectedSupporter}
              setSelectedSupporter={setSelectedSupporter}
              centerMap={centerMap}
              setCenterMap={setCenterMap}
              zoom={zoom}
              setZoom={setZoom}
            />
          </Grid>
        )}
      </Grid>

      {/* Pagination */}
      {!isListView && totalPages > 1 && (
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      )}
    </Box>
  );
};

export default SupportersView;

