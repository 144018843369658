// Define the structure of an organization object based on your backend model
interface Organization {
  id: number;
  name: string;
  sector: string;
  fiscal_year_start: string;
  crm: string;
  csm_id: number;
  domain: string;
  legacy_gift_threshold: number;
  major_gift_threshold: number;
  logo: string; // This is a base64-encoded string for the logo image
}

// Function to fetch all organizations
export const getOrganizations = async (): Promise<Organization[] | null> => {
  try {
    const response = await fetch(`/api/organizations`);
    if (response.ok) {
      const data = await response.json();
      return data.organizations;
    } else {
      console.error('Failed to fetch organizations:', await response.json());
      return null;
    }
  } catch (error) {
    console.error('Error fetching organizations:', error);
    return null;
  }
};

// Function to fetch a single organization by its ID
export const getOrganization = async (id: number): Promise<Organization | null> => {
  try {
    const response = await fetch(`/api/organizations/${id}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error('Failed to fetch organization:', await response.json());
      return null;
    }
  } catch (error) {
    console.error('Error fetching organization:', error);
    return null;
  }
};
