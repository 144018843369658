import React from 'react';
import { Box } from '@mui/material';
import SupportersView from './SupportersView';

const ViewSupporters: React.FC = () => {
  return (
    <Box 
      sx={{ 
        width: '85%', // Set to 75% of the screen width
        margin: '0 auto', // Centers the component horizontally
        padding: 2 // Optional padding for spacing
      }}
    >
      <SupportersView />
    </Box>
  );
};

export default ViewSupporters;