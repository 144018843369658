import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  MenuItem,
  Typography,
  Box,
  ListItemIcon,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import checkCircleIcon from '../icons/check_circle.svg';

interface Organization {
  id: number;
  name: string;
  sector: string;
  fiscal_year_start: string;
  crm: string;
  csm_id: number;
  domain: string;
  legacy_gift_threshold: number;
  major_gift_threshold: number;
  logo: string | ''; // Base64-encoded string for the logo image
}

interface ChangeOrganizationDialogProps {
  open: boolean;
  onClose: () => void;
  userOrgs: Organization[];
  selectedOrg: Organization | null;
  setSelectedOrg: React.Dispatch<React.SetStateAction<Organization | null>>;
  handleOrgSelect: (org: Organization) => void;
}

const ChangeOrganizationDialog: React.FC<ChangeOrganizationDialogProps> = ({
  open,
  onClose,
  userOrgs,
  selectedOrg,
  setSelectedOrg,
  handleOrgSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOrganizationSelect = (org: Organization) => {
    setSelectedOrg(org);
    handleOrgSelect(org);
    // Do not close the dialog here; keep it open until the user clicks "X".
  };

  // Filter organizations based on search term
  const filteredOrgs = userOrgs.filter((org) =>
    org.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: { borderRadius: '10px' }, // Increased border radius for a more rounded dialog
      }}
    >
      {/* Dialog Title Section */}
      <Box
        sx={{
          backgroundColor: '#DEF2FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 'bold', marginX: 3 }}>
          Change Organization
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent sx={{ height: '400px' }}> {/* Fixed height for the content */}
        <Box display="flex" justifyContent="center" mb={2}>
          <input
            type="text"
            placeholder="Search Organization"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #6C8EA5',
              background: 'rgba(108, 142, 165, 0.08)',
              fontFamily: 'Inter',
            }}
          />
        </Box>
        <Box maxHeight="300px" overflow="auto">
          {filteredOrgs.map((org) => (
            <MenuItem
              key={org.id}
              onClick={() => handleOrganizationSelect(org)}
              selected={selectedOrg?.id === org.id}
              disableRipple
              sx={{ justifyContent: 'space-between', borderRadius: '5px' }}
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography>{org.name}</Typography>
                {org.logo && (
                  <img
                    alt={org.name}
                    src={`data:image/png;base64,${org.logo}`}
                    style={{ height: '15px', width: 'auto', marginTop: 4 }}
                  />
                )}
              </Box>
              {selectedOrg?.id === org.id && (
                <ListItemIcon sx={{ minWidth: 0, marginLeft: 'auto' }}>
                  <img
                    src={checkCircleIcon}
                    alt="Selected"
                    style={{ height: '24px', width: '24px' }}
                  />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeOrganizationDialog;
