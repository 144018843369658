import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

interface CustomLinearProgressBarProps {
  progress: number;
}

const CustomLinearProgressBar: React.FC<CustomLinearProgressBarProps> = ({ progress }) => {
  return (
    <Box sx={{ 
      width: '75%', 
      display: 'flex', 
      alignItems: 'center', 
      margin: 'auto', 
      marginTop: '50px',
      position: 'relative'
    }}>
      <LinearProgress 
        variant="determinate"
        value={progress}
        sx={{
          width: '90%', // leaving some space for the percentage
          height: '32px', 
          borderRadius: '25px',
          border: '1px solid #C8D7E1',
          backgroundColor: '#FFF',
          '& .MuiLinearProgress-bar': {
            borderRadius: '25px',
            backgroundImage: 'linear-gradient(90deg, #7C90A3 0%, #C8D7E1 100%)',
            backgroundColor: 'transparent'
          }
        }}
      />
      <Typography variant="body1" sx={{
        position: 'absolute',
        right: '0px',
        top: '50%',
        transform: 'translateY(-50%)',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        color: '#7C90A3',
        minWidth: '50px',
        paddingLeft: '24px'  // Space between the end of the bar and the percent text
      }}>
        {`${Math.floor(progress)}%`}
      </Typography>
    </Box>
  );
};

export default CustomLinearProgressBar;
