import React, { useState } from 'react';
import { LanguageProvider } from './LanguageContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import RoutesTree from './components/RoutesTree';
import { GlobalStyles } from '@mui/material';

const globalStyles = `
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inter Regular'), local('Inter-Regular'),
         url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap') format('woff2');
  }
`;

const App: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [avatarEl, setAvatarEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setAvatarEl(null);
  };

  const handleOrgSelect = (org: any) => {
    if (setSelectedOrg) {
      setSelectedOrg(org);
    }
    handleClose();
  };
  

  return (
    <>
      <GlobalStyles styles={{ '@global': { '@font-face': [globalStyles] }}} />
        <AuthProvider>
          <LanguageProvider>
              <RoutesTree isLoggedIn={isLoggedIn} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect} />
          </LanguageProvider>
        </AuthProvider>
    </>
  );
};

export default App;
