import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, IconButton, Menu, MenuItem, Box
} from '@mui/material';
import { Home, Business, Upload, ExpandMore, Group, Margin } from '@mui/icons-material';
import logo from './assets/images/marketsmart-logo-white.png';
import { useAuth } from './contexts/AuthContext';
import { getOrganizations } from '../src/api/orgService';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import ProfileDropdown from './components/organization/ProfileDropdown';

// Updated Organization interface with full structure
interface Organization {
  id: number;
  name: string;
  sector: string;
  fiscal_year_start: string;
  crm: string;
  csm_id: number;
  domain: string;
  legacy_gift_threshold: number;
  major_gift_threshold: number;
  logo: string | ""; // Base64-encoded string for the logo image
}

interface BannerProps {
  selectedOrg: Organization | null;
  setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
  handleOrgSelect: (org: Organization) => void;
  handleClose: () => void;
  anchorEl?: HTMLElement | null;
  avatarEl?: HTMLElement | null;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setAvatarEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const Banner: React.FC<BannerProps> = ({
  selectedOrg,
  setSelectedOrg,
  handleOrgSelect,
  handleClose,
  anchorEl,
  avatarEl,
  setAnchorEl,
  setAvatarEl,
}) => {
  const { logout, user } = useAuth();
  const [userOrgs, setUserOrgs] = useState<Organization[]>([]);
  const isOrgMenuOpen = Boolean(anchorEl);
  const isUserMenuOpen = Boolean(avatarEl);

  useEffect(() => {
    const getAllOrgs = async () => {
      try {
        const orgs = await getOrganizations();
        console.log(orgs);
        if (orgs && orgs.length > 0) {
          // Ensure organizations match the correct structure
          const formattedOrgs: Organization[] = orgs.map((org: any) => ({
            id: org.id,
            name: org.name,
            sector: org.sector,
            fiscal_year_start: org.fiscal_year_start,
            crm: org.crm,
            csm_id: org.csm_id,
            domain: org.domain,
            legacy_gift_threshold: org.legacy_gift_threshold,
            major_gift_threshold: org.major_gift_threshold,
            logo: org.logo || "defaultBase64String", // Provide a default base64 logo if necessary
          }));

          setUserOrgs(formattedOrgs);

          // Set the first organization as the selected organization if none is selected
          if (!selectedOrg) {
            setSelectedOrg(formattedOrgs[0]);
          }
        }
      } catch (error) {
        toast.error('Failed to fetch organizations');
      }
    };

    if (userOrgs.length === 0) {
      getAllOrgs();
    }
  }, [userOrgs.length, selectedOrg, setSelectedOrg]);

  const handleOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl?.(event.currentTarget);
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarEl?.(event.currentTarget);
  };

  return (
    user && (
      <AppBar position="fixed" style={{ backgroundColor: '#004F6E', padding: '0 10px' }}>
        <Toolbar>
          <Link to="/dashboard" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
            <img src={logo} alt="Marketsmart" style={{ marginRight: 16 }} />
          </Link>
          <div style={{ flexGrow: 1 }} />
          <Link to="/view-supporters" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', marginRight: '15px' }}>
            <IconButton color="inherit">
              <Group />
            </IconButton>
          </Link>
          <Menu anchorEl={anchorEl} open={isOrgMenuOpen} onClose={handleClose}>
            {userOrgs.map((org) => (
              <Link key={org.id} to="/organization" style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}>
                <MenuItem onClick={() => handleOrgSelect(org)}>
                  {org.name}
                </MenuItem>
              </Link>
            ))}
          </Menu>
          {/* Use the ProfileDropdown with dynamic data */}
          <ProfileDropdown
            userOrgs={userOrgs}
            handleOrgSelect={handleOrgSelect}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
          />
        </Toolbar>
      </AppBar>
    )
  );
};

export default Banner;
