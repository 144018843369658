import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import FooterButtons from '../FooterButtons';
import { getUserLists } from '../../api/supporterService';
import { useAuth } from '../../contexts/AuthContext';

interface ConfirmBulkActionDialogProps {
  open: boolean;
  onClose: () => void;
  actionType: string;
  selectedSupporterCount: number;
  selectedList: any; // Prop to hold the selected list
  setSelectedList: (list: any) => void; // Function to update the selected list in SupportersView
  onConfirm: () => void; // Callback to trigger handleDialogConfirm
}

const ConfirmBulkActionDialog: React.FC<ConfirmBulkActionDialogProps> = ({
  open,
  onClose,
  actionType,
  selectedSupporterCount,
  selectedList,
  setSelectedList, // This will update the selectedList state in SupportersView
  onConfirm,
}) => {
  // State for dropdown selection
  const [selectedOfficer, setSelectedOfficer] = useState<string>('');
  const [selectedSigner, setSelectedSigner] = useState<string>('');
  const [lists, setLists] = useState<any[]>([]); // State to store fetched lists
  const { user } = useAuth();

  // Handlers for dropdown changes
  const handleListChange = (event: SelectChangeEvent<string>) => {
    const selected = lists.find((list) => list.id === event.target.value);
    setSelectedList(selected); // Set the entire list object
  };

  const handleOfficerChange = (event: SelectChangeEvent<string>) => {
    setSelectedOfficer(event.target.value);
  };

  const handleSignerChange = (event: SelectChangeEvent<string>) => {
    setSelectedSigner(event.target.value);
  };

  const handleConfirmClick = () => {
    onConfirm(); // Trigger handleDialogConfirm from SupportersView
  };

  // Fetch lists on component mount
  useEffect(() => {
    if (actionType === 'Save to List' || actionType === 'Remove from List') {
      // if not logged in, log the error and return
      if (!user) {
        console.error('User not logged in');
        return;
      }
      const fetchLists = async () => {
        try {
          const response = await getUserLists(user.id); // Hardcode user ID to 3 for now
          setLists(response.results); // Set the fetched lists to the state
        } catch (error) {
          console.error('Error fetching user lists:', error);
        }
      };
      fetchLists();
    }
  }, [actionType]);

  // Render dialog content based on the action type
  const renderDialogContent = () => {
    switch (actionType) {
      case 'Unsubscribe':
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have selected <strong>{selectedSupporterCount} supporters</strong> to unsubscribe.
            </Typography>
            <Typography variant="body1">
              Unsubscribing the selected supporters means that they will no longer receive ANY email communications from the
              MarketSmart Engagement Fundraising System, including:
            </Typography>
            <ul>
              <li>invite emails,</li>
              <li>form submission thank you emails,</li>
              <li>ongoing cultivation emails.</li>
            </ul>
          </>
        );
      case 'Resubscribe':
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have selected <strong>{selectedSupporterCount} supporters</strong> to re-subscribe.
            </Typography>
            <Typography variant="body1">
              Resubscribing the selected supporters means that they will receive emails again from the MarketSmart Engagement
              Fundraising System, including:
            </Typography>
            <ul>
              <li>invite emails,</li>
              <li>form submission thank you emails,</li>
              <li>ongoing cultivation emails.</li>
            </ul>
          </>
        );
      case 'Mark as Deceased':
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have selected <strong>{selectedSupporterCount} supporters</strong> to mark as deceased.
            </Typography>
            <Typography variant="body1">
              Marking a supporter as deceased will also automatically unsubscribe them from all email communications from the
              MarketSmart Engagement Fundraising System.
            </Typography>
          </>
        );
      case 'Un-mark as Deceased':
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have selected <strong>{selectedSupporterCount} supporters</strong> to un-mark as deceased.
            </Typography>
            <Typography variant="body1">
              The subscriber will be re-subscribed to receive email communications from the MarketSmart Engagement Fundraising System.
            </Typography>
          </>
        );
        case 'Save to List':
          return (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                You have selected <strong>{selectedSupporterCount} supporters</strong> to save to a list.
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                Choose a list:
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedList ? selectedList.id : ''} // Use the ID of the selected list
                  onChange={handleListChange}
                  displayEmpty
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="" disabled>
                    Select List
                  </MenuItem>
                  {lists.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.name} {/* Display list name */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          );
        case 'Remove from List':
          return (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                You have selected <strong>{selectedSupporterCount} supporters</strong> to remove from a list.
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                Choose a list:
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedList ? selectedList.id : ''} // Use the ID of the selected list
                  onChange={handleListChange}
                  displayEmpty
                  sx={{ height: '40px' }}
                >
                  <MenuItem value="" disabled>
                    Select List
                  </MenuItem>
                  {lists.map((list) => (
                    <MenuItem key={list.id} value={list.id}>
                      {list.name} {/* Display list name */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          );
      case 'Assign Gift Officer':
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have selected <strong>{selectedSupporterCount} supporters</strong> to assign to a gift officer.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
              Choose a gift officer:
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedOfficer}
                onChange={handleOfficerChange}
                displayEmpty
                sx={{ height: '40px' }}
              >
                <MenuItem value="" disabled>
                  Select Gift Officer
                </MenuItem>
                <MenuItem value="officer1">Officer 1</MenuItem>
                <MenuItem value="officer2">Officer 2</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      case 'Assign Email Signer':
        return (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              You have selected <strong>{selectedSupporterCount} supporters</strong> to assign an email signer.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
              Choose email signer:
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedSigner}
                onChange={handleSignerChange}
                displayEmpty
                sx={{ height: '40px' }}
              >
                <MenuItem value="" disabled>
                  Select Email Signer
                </MenuItem>
                <MenuItem value="signer1">Signer 1</MenuItem>
                <MenuItem value="signer2">Signer 2</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      default:
        return <Typography variant="body1">Are you sure you want to perform this action?</Typography>;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Box sx={{ backgroundColor: '#DEF2FF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px' }}>
        <Typography variant="h5" sx={{ fontFamily: 'Inter', fontWeight: 'bold', marginX: 2 }}>{actionType}</Typography>
        <IconButton onClick={onClose}><Close /></IconButton>
      </Box>
      <DialogContent sx={{ padding: '24px', textAlign: 'left' }}>
        {renderDialogContent()}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mt: '-40px', mb: '25px' }}>
        <FooterButtons onClickCancel={onClose} onClickConfirm={handleConfirmClick} confirmLabel="Save" cancelLabel="Cancel" gap="75px" />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBulkActionDialog;
