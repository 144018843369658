import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  IconButton,
  styled,
  Pagination,
  PaginationItem,
  Checkbox,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Define the type for the supporter prop
interface Supporter {
  salutation?: string | null;
  assigned_gift_officer_id?: number | null;
  unsubscribed?: boolean | null;
  external_id?: string | null;
  group_id?: number | null;
  org_id?: number | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  image?: string | null;
  version?: number;
  is_head?: boolean;
  id: number;
  created_at?: string;
  created_by?: string | null;
  advancement_stage?: string | null;
}

interface SupportersTableProps {
  supporters: Supporter[];
  handleSupporterClick: (supporter: Supporter) => void;
  onSelectSupporters: (selectedIds: number[]) => void;
}

const tableBoxStyle = {
  borderRadius: '5px',
  border: '2px solid #C8D7E1',
  background: '#FFF',
  overflow: 'hidden',
};

const tableStyle = {
  minWidth: 650,
  '& thead th': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    borderBottom: '2px solid #C8D7E1',
    padding: '3px 8px',
    whiteSpace: 'nowrap',
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: '#FFF',
  },
  '& tbody tr:nth-of-type(even)': {
    backgroundColor: '#ECF4F9',
  },
  '& tbody td': {
    fontFamily: 'Inter, sans-serif',
    fontSize: '1rem',
    borderBottom: '0px',
    padding: '3px 8px',
    whiteSpace: 'nowrap',
  },
};

const tableContainerStyle = {
  overflowX: 'auto',
};

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
  backgroundColor: '#FFF',
});

const SupportersTable: React.FC<SupportersTableProps> = ({ supporters = [], handleSupporterClick, onSelectSupporters }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSupporters, setSelectedSupporters] = useState<number[]>([]);
  const rowsPerPage = 7;

  const handlePageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selectedSupporters.indexOf(id);
    let newSelected: number[] = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSupporters, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSupporters.slice(1));
    } else if (selectedIndex === selectedSupporters.length - 1) {
      newSelected = newSelected.concat(selectedSupporters.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSupporters.slice(0, selectedIndex),
        selectedSupporters.slice(selectedIndex + 1)
      );
    }
  
    setSelectedSupporters(newSelected);
    onSelectSupporters(newSelected);  // Call the callback function with the updated selection
  };
  
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = paginatedSupporters.map((supporter) => supporter.id);
      setSelectedSupporters(newSelecteds);
      onSelectSupporters(newSelecteds);  // Update the state in SupportersView
    } else {
      setSelectedSupporters([]);
      onSelectSupporters([]);  // Reset selection
    }
  };
  

  const totalPages = Math.ceil(supporters.length / rowsPerPage);
  const paginatedSupporters = supporters.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const isSelected = (id: number) => selectedSupporters.indexOf(id) !== -1;
  const allSelected =
    paginatedSupporters.length > 0 && paginatedSupporters.every((supporter) => isSelected(supporter.id));

  return (
    <Box sx={tableBoxStyle}>
      <TableContainer sx={tableContainerStyle}>
        <Table sx={tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ padding: '0 4px' }}>
                <Checkbox
                  size="medium"
                  indeterminate={selectedSupporters.length > 0 && !allSelected}
                  checked={allSelected}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all supporters' }}
                  disableRipple
                />
              </TableCell>
              {/* For now I am including these, but they can be easily changed once we know better what we want */}
              <TableCell>Name</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Advancement Stage</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedSupporters.map((supporter, index) => (
              <TableRow key={index} selected={isSelected(supporter.id)} onClick={() => handleSupporterClick(supporter)}>
                <TableCell padding="checkbox" sx={{ padding: '0 4px' }}>
                  <Checkbox
                    size="medium"
                    checked={isSelected(supporter.id)}
                    onChange={() => handleCheckboxClick(supporter.id)}
                    inputProps={{ 'aria-label': `select supporter ${supporter.id}` }}
                    disableRipple
                  />
                </TableCell>
                <TableCell>{`${supporter.first_name} ${supporter.last_name}`.trim()}</TableCell>
                <TableCell>{''}</TableCell>
                <TableCell>{''}</TableCell>
                <TableCell>{supporter.email || ''}</TableCell>
                <TableCell>{supporter.phone_number || ''}</TableCell>
                <TableCell>{supporter.address || ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationWrapper>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIosNewIcon, next: ArrowForwardIosIcon }}
              {...item}
            />
          )}
        />
      </PaginationWrapper>
    </Box>
  );
};

export default SupportersTable;
