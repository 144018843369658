import React, { useState } from 'react';
import { Card, CardContent, Button, Typography, Grid, Box } from '@mui/material';
import ImportCsv from '../../../components/ImportCSV';
import { ImportCSVProvider } from '../../../contexts/ImportCSVContext.js';
import ReviewData from '../../../components/ReviewData';

interface ImportOptionProps {
  title: string;
  description: string;
  future?: boolean;
  onSelect: () => void;
}

const ImportOption: React.FC<ImportOptionProps> = ({ title, description, future, onSelect }) => {
  return (
    <Grid item xs={12} sm={6} style={{ padding: '20px' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {title} {future && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <Typography variant="body2">
            {description}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
            <Button variant="contained" onClick={onSelect} disabled={future} sx={{ color: '#000000', backgroundColor: `${future ? '#ccc' : '#C8D7E1'}`, 
              '&:hover': {
                backgroundColor: `${future ? '#ccc' : '#A9C0CD'}`,
                color: '#000000',
                cursor: `${future ? 'not-allowed' : 'pointer'}`
              }
          }}>
              Select
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

const ImportSupporters: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<'select' | 'importCsv' | 'importCrm'>('select');

  const handleStepChange = (step: 'select' | 'importCsv' | 'importCrm') => {
    setCurrentStep(step);
  };

  return (
  //   <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '400px' }}>
      <Grid container spacing={2} style={{ padding: '20px' }}>
        <Grid item xs={12}>
      {currentStep === 'select' && (
        <Grid container spacing={2} sx={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ImportOption
            title="Import from CSV"
            description="Import your supporters from a CSV file."
            onSelect={() => handleStepChange('importCsv')}
          />
          <ImportOption
            title="Import from CRM"
            description="Import your supporters from your CRM."
            future={true}
            onSelect={() => handleStepChange('importCrm')}
          />
        </Grid>
      )}
      {currentStep === 'importCsv' && (
        // <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" width="100%">
        <ImportCSVProvider>
          <ImportCsv cancel={handleStepChange} />
        </ImportCSVProvider>
        // </Grid>
      )}
    </Grid>
    </Grid>
  );
};

export default ImportSupporters;