import React from "react";
import { Route, Routes } from "react-router-dom";

// component imports
import Dashboard from "../views/dashboard";
import OrgPage from "../views/org";
import UserPage from "../views/user";
import ImportSupporters from "../views/supporters/import";
import Supporters from "../views/supporters";
import NotFoundPage from "../views/NotFoundPage";
import Layout from "../pages/layout";
import LoginPage from "../views/auth/login";
import withAuth from "../hoc/withAuth";
import ViewSupporters from "../views/supporters/view_supporters";

interface Props {
    isLoggedIn: boolean;
    selectedOrg: any;
    setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
    anchorEl: HTMLElement | null;
    avatarEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setAvatarEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    handleClose: () => void;
    handleOrgSelect: (org: any) => void;
}



const RoutesTree: React.FC<Props> = ({ isLoggedIn, selectedOrg, setSelectedOrg, anchorEl, avatarEl, setAnchorEl, setAvatarEl, handleClose, handleOrgSelect }) => {
  const ProtectedDashboard = withAuth(Dashboard);
  const ProtectedOrgPage = withAuth(OrgPage);
  const ProtectedUserPage = withAuth(UserPage);
  const ProtectedImportSupporters = withAuth(ImportSupporters);
  const ProtectedSupporters = withAuth(Supporters);
  const ProtectedViewSupporters = withAuth(ViewSupporters);


    return (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<Layout selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect}><ProtectedDashboard /></Layout>} />
            <Route path="/supporters" element={<Layout selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect}><ProtectedSupporters /></Layout>} />
            <Route path="/import-supporters" element={<Layout selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect}><ProtectedImportSupporters /></Layout>} />
            <Route path="/users" element={<Layout selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect}><ProtectedUserPage /></Layout>} />
            <Route path="/organization" element={<Layout selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect}><ProtectedOrgPage selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg}/></Layout>} />
            <Route path="/view-supporters" element={<Layout selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} handleClose={handleClose} handleOrgSelect={handleOrgSelect}><ProtectedViewSupporters /></Layout>} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
    };

export default RoutesTree;