import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login as authenticate, getCurrentUser, logout as apiLogout, ssoLogin } from "../api/authService";
import { Box, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  platformRole: string;
  organizationRole: string;
}

interface AuthContextType {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: User | null;
  login: (userOrEmail: string, password: string) => Promise<void>;
  ssoLogin: (email: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = sessionStorage.getItem('jwtToken');
      if (token) {
        try {
          const user = await getCurrentUser();
          if (user) {
            setIsLoggedIn(true);
            setUser({
              id: user.id,
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              phoneNumber: user.phone_number,
              platformRole: user.platform_role,
              organizationRole: user.organization_role,
            });
          } else {
            sessionStorage.removeItem("jwtToken");
            setIsLoggedIn(false);
            setUser(null);
          }
        } catch (error) {
          console.error("Error verifying token:", error);
          sessionStorage.removeItem("jwtToken");
          setIsLoggedIn(false);
          setUser(null);
        }
      }
      setIsLoading(false);
    };

    checkLoginStatus();
  }, []);

  const login = async (userOrEmail: string, password: string) => {
    setIsLoading(true);
    try {
      const token = await authenticate(userOrEmail, password);
      if (token) {
        sessionStorage.setItem("jwtToken", token);
        const user = await getCurrentUser();
        if (user) {
          setIsLoggedIn(true);
          setUser({
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            phoneNumber: user.phone_number,
            platformRole: user.platform_role,
            organizationRole: user.organization_role,
          });
          navigate("/dashboard");
        } else {
          sessionStorage.removeItem("jwtToken");
          setIsLoggedIn(false);
          setUser(null);
          toast.error("Login failed: Please check your credentials and try again.", {
            style: {
              backgroundColor: "#f44336",
              color: "#ffffff",
            },
            iconTheme: {
              primary: "#ffffff",
              secondary: "#f44336",
            },
            position: "top-center",
          });
        }
      } else {
        sessionStorage.removeItem("jwtToken");
        setIsLoggedIn(false);
        setUser(null);
        toast.error("Authentication failed. Please try again.", {
          style: {
            backgroundColor: "#f44336",
            color: "#ffffff",
          },
          iconTheme: {
            primary: "#ffffff",
            secondary: "#f44336",
          },
          position: "top-center",
        });
      }
    } catch (error: any) {
      console.error("Login error:", error);
      sessionStorage.removeItem("jwtToken");
      setIsLoggedIn(false);
      setUser(null);
      toast.error("Login error: " + error.message, {
        style: {
          backgroundColor: "#f44336",
          color: "#ffffff",
        },
        iconTheme: {
          primary: "#ffffff",
          secondary: "#f44336",
        },
        position: "top-center",
      });
    }
    setIsLoading(false);
  };

  // New SSO login function
  const initiateSSOLogin = async (email: string) => {
    setIsLoading(true);
    try {
      await ssoLogin(email);
    } catch (error: any) {
      console.error("SSO login error:", error);
      toast.error("SSO login error: " + error.message, {
        style: { backgroundColor: "#f44336", color: "#ffffff" },
        iconTheme: { primary: "#ffffff", secondary: "#f44336" },
        position: "top-center",
      });
    }
    setIsLoading(false);
  };

  const logout = useCallback(() => {
    apiLogout(); 
    sessionStorage.removeItem("jwtToken");
    setIsLoggedIn(false);
    setUser(null);
    navigate("/login");
    toast.success("You have been logged out.", {
      style: {
        backgroundColor: "#4caf50",
        color: "#ffffff",
      },
      iconTheme: {
        primary: "#ffffff",
        secondary: "#4caf50",
      },
      position: "top-center",
    });
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, isLoading, user, ssoLogin: initiateSSOLogin }}>
      {isLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
