import React, { useState } from 'react';
import { Menu, MenuItem, Box, Typography, ListItemIcon, Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import accountCircleIcon from '../icons/account_circle.svg';
import checkCircleIcon from '../icons/check_circle.svg';
import ChangeOrganizationDialog from './ChangeOrganizationDialog';
import { useAuth } from '../../contexts/AuthContext';

interface Organization {
  id: number;
  name: string;
  sector: string;
  fiscal_year_start: string;
  crm: string;
  csm_id: number;
  domain: string;
  legacy_gift_threshold: number;
  major_gift_threshold: number;
  logo: string | ''; // Base64-encoded string for the logo image
}

interface ProfileDropdownProps {
  userOrgs: Organization[];
  handleOrgSelect: (org: Organization) => void;
  selectedOrg: Organization | null;
  setSelectedOrg: React.Dispatch<React.SetStateAction<Organization | null>>;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  userOrgs,
  handleOrgSelect,
  selectedOrg,
  setSelectedOrg
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog
  const { logout } = useAuth(); // Use the logout function from the Auth context

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOrganizationSelect = (org: Organization) => {
    setSelectedOrg(org);
    handleOrgSelect(org);
    handleMenuClose();
  };

  return (
    <Box display="flex" alignItems="center">
      {/* Clickable white box for profile picture and organization logo */}
      <Box
        display="flex"
        alignItems="center"
        bgcolor="white"
        borderRadius="8px"
        p={1}
        sx={{ cursor: 'pointer' }}
        onClick={handleMenuOpen}
      >
        {/* Profile picture using the imported SVG */}
        <img
          src={accountCircleIcon}
          alt="Profile"
          style={{ height: '25px', width: '25px' }}
        />

        {/* Selected organization logo */}
        {selectedOrg?.logo && (
          <img
            alt={selectedOrg.name}
            src={`data:image/png;base64,${selectedOrg.logo}`}
            style={{ height: '20px', width: 'auto', marginLeft: 15 }}
          />
        )}
      </Box>

      {/* Organization menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          sx: {
            width: 275,
            '& .MuiMenuItem-root': {
              border: '1px solid transparent',
              marginX: '8px',
              '&:hover': {
                borderColor: '#C8D7E1',
                backgroundColor: 'rgba(197, 225, 243, 0.27)',
                borderRadius: '5px',
                marginX: '8px',
              },
              padding: '8px 16px',
            },
          },
        }}
      >
        {userOrgs.length > 5 ? (
          <Button
            onClick={() => setDialogOpen(true)} // Open the dialog
            sx={{
              borderRadius: '5px',
              border: '1px solid #6C8EA5',
              background: 'rgba(197, 225, 243, 0.46)',
              color: '#33475B',
              fontFamily: 'Inter',
              padding: '8px 16px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            Change Organization
          </Button>
        ) : (
          userOrgs.map((org) => (
            <MenuItem
              key={org.id}
              onClick={() => handleOrganizationSelect(org)}
              selected={selectedOrg?.id === org.id}
              sx={{ justifyContent: 'space-between', borderRadius: selectedOrg?.id === org.id ? '5px' : '0px' }}
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography>{org.name}</Typography>
                {org.logo && (
                  <img
                    alt={org.name}
                    src={`data:image/png;base64,${org.logo}`}
                    style={{ height: '15px', width: 'auto', marginTop: 4 }}
                  />
                )}
              </Box>
              {selectedOrg?.id === org.id && (
                <ListItemIcon sx={{ minWidth: 0, marginLeft: 4, borderRadius: '5px' }}>
                  <img
                    src={checkCircleIcon}
                    alt="Selected"
                    style={{ height: '20px', width: '20px' }}
                  />
                </ListItemIcon>
              )}
            </MenuItem>
          ))
        )}

        <Divider sx={{ my: 1, backgroundColor: '#e0e0e0' }} />
        <Link to="/users" style={{ color: '#33475B', textDecoration: 'none' }}>
          <MenuItem sx={{ fontFamily: 'Inter' }} onClick={handleMenuClose}>
            My Profile
          </MenuItem>
        </Link>
        <Link to={`/organization/${selectedOrg?.id}`} style={{ color: '#33475B', textDecoration: 'none' }}>
          <MenuItem sx={{ fontFamily: 'Inter' }} onClick={handleMenuClose}>
            Organization Profile
          </MenuItem>
        </Link>
        <MenuItem sx={{ color: '#33475B', fontFamily: 'Inter' }} onClick={handleMenuClose}>
          Manage Subscriptions
        </MenuItem>
        <MenuItem sx={{ color: '#33475B', fontFamily: 'Inter' }} onClick={handleMenuClose}>
          Manage Users
        </MenuItem>
        <Divider sx={{ my: 1, backgroundColor: '#e0e0e0' }} />
        <MenuItem sx={{ color: '#33475B', fontFamily: 'Inter' }} onClick={() => { logout(); handleMenuClose(); }}>
          Log Out
        </MenuItem>
      </Menu>

      {/* Change Organization Dialog */}
      <ChangeOrganizationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        userOrgs={userOrgs}
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        handleOrgSelect={handleOrgSelect}
      />
    </Box>
  );
};

export default ProfileDropdown;
