import { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';
import { getGeoLocation } from '../../api/utils';

interface Supporters {
  salutation?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  id: number;
  engagementScore?: number;
  considerationStage?: string;
  lat?: number;
  lng?: number;
}

interface SupportersMapProps {
  supporters: Supporters[];
  selectedSupporter: Supporters | null;
  setSelectedSupporter: (supporter: Supporters | null) => void;
  centerMap: { lat: number; lng: number };
  setCenterMap: (center: { lat: number; lng: number }) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
}

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const SupportersMap: React.FC<SupportersMapProps> = ({
  supporters,
  selectedSupporter,
  setSelectedSupporter,
  centerMap,
  setCenterMap,
  zoom,
  setZoom,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [geocodedSupporters, setGeocodedSupporters] = useState<Supporters[]>([]);
  const [infoWindowPos, setInfoWindowPos] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    const geocodeSupporters = async () => {
      const promises = supporters.map(async (supporter) => {
        const address = `${supporter.address}, ${supporter.city}, ${supporter.state} ${supporter.zip_code}`;
        try {
          const response = await getGeoLocation(address);
          if (response.results && response.results.length > 0) {
            const { lat, lng } = response.results[0].geometry.location;
            console.log('Geocoded:', lat, lng);
            return { ...supporter, lat, lng };
          } else {
            return { ...supporter, lat: null, lng: null };
          }
        } catch (error) {
          console.error('Geocoding error:', error);
          return { ...supporter, lat: null, lng: null };
        }
      });

      const results = await Promise.all(promises);
      setGeocodedSupporters(results);
    };

    if (supporters && supporters.length > 0) {
      geocodeSupporters();
    }
  }, [supporters]);

  // This useEffect triggers when a new supporter is selected
  useEffect(() => {
    if (selectedSupporter) {
      const matchedSupporter = geocodedSupporters.find(
        (supporter) => supporter.id === selectedSupporter.id
      );

      if (matchedSupporter && matchedSupporter.lat != null && matchedSupporter.lng != null) {
        setCenterMap({ lat: matchedSupporter.lat, lng: matchedSupporter.lng });
        setZoom(10); // Zoom in to the selected supporter's location
        setInfoWindowPos({ lat: matchedSupporter.lat, lng: matchedSupporter.lng }); // Set the position for InfoWindow
      }
    }
  }, [selectedSupporter, geocodedSupporters, setCenterMap, setZoom]);

  const handleMarkerClick = (supporter: Supporters) => {
    setSelectedSupporter(supporter);
    setInfoWindowPos({ lat: supporter.lat as number, lng: supporter.lng as number });
  };

  const handleCloseInfoWindow = () => {
    setSelectedSupporter(null);
    setInfoWindowPos(null);
  };

  const handleBoundsChanged = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const visibleSupporters = geocodedSupporters.filter((supporter) => {
          if (supporter.lat && supporter.lng) {
            return bounds.contains({ lat: supporter.lat, lng: supporter.lng });
          }
          return false;
        });
        // Update logic as needed if filtering is required.
      }
    }
  };

  return (
    <Box sx={{ borderRadius: '5px', overflow: 'hidden' }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={centerMap}
        onLoad={(map) => {
          mapRef.current = map;
        }}
        onBoundsChanged={handleBoundsChanged}
        options={{
          gestureHandling: 'greedy', // Improve responsiveness
        }}
      >
        {geocodedSupporters.map(
          (supporter) =>
            supporter.lat &&
            supporter.lng && (
              <Marker
                key={supporter.email}
                position={{ lat: supporter.lat, lng: supporter.lng }}
                onClick={() => handleMarkerClick(supporter)}
              />
            )
        )}

        {infoWindowPos && selectedSupporter && (
          <InfoWindow position={infoWindowPos} onCloseClick={handleCloseInfoWindow}>
            <div>
              <Typography variant="h6">
                {selectedSupporter.salutation} {selectedSupporter.first_name} {selectedSupporter.last_name}
              </Typography>
              <Typography>
                Address: {selectedSupporter.address}, {selectedSupporter.city}, {selectedSupporter.state} {selectedSupporter.zip_code}
              </Typography>
              <Typography>Phone: {selectedSupporter.phone_number}</Typography>
              <Typography>Engagement Score: {selectedSupporter.engagementScore}</Typography>
              <Typography>Consideration Stage: {selectedSupporter.considerationStage}</Typography>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </Box>
  );
};

export default SupportersMap;
