import { saveAs } from 'file-saver';

// List of columns to exclude from the CSV export
const EXCLUDED_COLUMNS = ['task_id', 'id', 'created_at', 'created_by'];

// Convert JSON to CSV
export const jsonToCsv = (jsonArray: Record<string, any>[]): string => {
  if (jsonArray.length === 0) return '';

  // Get the keys and filter out the excluded columns
  const keys = Object.keys(jsonArray[0]).filter(key => !EXCLUDED_COLUMNS.includes(key));

  // Create CSV rows with filtered keys
  const csvRows = jsonArray.map(row =>
    keys.map(key => JSON.stringify(row[key], (_, val) => (val === null ? '' : val))).join(',')
  );

  // Join headers and rows into a CSV string
  return [keys.join(','), ...csvRows].join('\n');
};

// Utility to get the current date and time in the format 'YYYY-MM-DD_HH-MM-SS'
const getCurrentDateTimeString = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
};

// Export JSON data as a CSV file with a timestamped filename
export const exportToCsv = (data: object[], fileName: string) => {
  if (!data || data.length === 0) {
    console.error('No data to export');
    return;
  }

  const csvData = jsonToCsv(data);
  const timestamp = getCurrentDateTimeString();
  const fileNameWithDate = `${fileName.replace('.csv', '')}_${timestamp}.csv`;

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileNameWithDate);
};
