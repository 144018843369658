import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, CssBaseline, Container, Card } from '@mui/material';
import marketsmartLogo from '../../../assets/images/marketsmart-logo.png';
import { useAuth } from '../../../contexts/AuthContext';

// Validation schema for the email field
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

const LoginPage: React.FC = () => {
  const { login, ssoLogin } = useAuth();
  const [isSSO, setIsSSO] = useState(false); // State to track if SSO view is enabled

  const toggleSSO = () => {
    setIsSSO(!isSSO); // Toggle the SSO view
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <Card sx={{ mt: 3, width: '100%', p: 3, boxShadow: 3 }}>
          <Typography component="h1" variant="h5" textAlign="center">Login</Typography>
          <Box display="flex" justifyContent="center">
            <Box component="img" src={marketsmartLogo} alt="Marketsmart logo" sx={{ height: 50, mt: 2, mb: 4 }} />
          </Box>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (isSSO) {
                // Call SAML SSO login service
                ssoLogin(values.email).finally(() => setSubmitting(false));
              } else {
                // Call regular login service
                login(values.email, values.password).finally(() => setSubmitting(false));
              }
            }}
          >
            {({ errors, touched, isSubmitting, handleChange, handleBlur }) => (
              <Form>
                {isSSO ? (
                  <Field as={TextField}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <>
                    <Field as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address or Username"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Field as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, p: 2 }}
                  disabled={isSubmitting}
                >
                  {isSSO ? "SSO Login" : "Login"}
                </Button>
              </Form>
            )}
          </Formik>
          <Box mt={2} textAlign="center">
            <Button color="primary" onClick={toggleSSO}>
              {isSSO ? "Use Username and Password" : "Use SSO"}
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginPage;
