import React from 'react';
import Banner from '../../Banner';

interface HeaderProps {
    selectedOrg?: any;
    setSelectedOrg: React.Dispatch<React.SetStateAction<any>>;
    handleOrgSelect: (org: any) => void;
    handleClose: () => void;
    anchorEl?: HTMLElement | null;
    avatarEl?: HTMLElement | null;
    setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setAvatarEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const Header: React.FC<HeaderProps> = ({ selectedOrg, setSelectedOrg, handleOrgSelect, handleClose, anchorEl, avatarEl, setAnchorEl, setAvatarEl }) => {

    return (
        <div>
            <Banner selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} handleOrgSelect={handleOrgSelect} handleClose={handleClose} anchorEl={anchorEl} avatarEl={avatarEl} setAnchorEl={setAnchorEl} setAvatarEl={setAvatarEl} />
        </div>
    );
};

export default Header;