import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Box,
  Grid,
  styled,
} from '@mui/material';

// Define the Person interface
interface Person {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string | null; // Accept null values
  image?: string | null;
  address?: string | null; // Accept null values
}

interface SupporterCardHorizontalProps {
  person: Person; // Accept a person object
}

const StyledCardHorizontal = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 850,
  borderRadius: 10,
  border: '2px solid #C8D7E1',
  boxShadow: 'none',
  margin: '0 auto',
  fontFamily: 'Inter, sans-serif',
  height: '250px',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  marginBottom: theme.spacing(1),
  backgroundColor: '#E0E0E0',
  color: '#555555',
  fontSize: '36px',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#FFCE01',
  borderWidth: '1px',
  opacity: 0.5,
  margin: theme.spacing(1, 0),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  letterSpacing: 0.75,
  color: '#0490C8',
  fontFamily: 'Inter, sans-serif',
}));

const SupporterCardHorizontal: React.FC<SupporterCardHorizontalProps> = ({ person }) => {
  // Default to empty string if any field is undefined
  const {
    first_name = '',
    last_name = '',
    email = '',
    phone_number = '',
    image,
    address = '',
  } = person;

  // Get the initials from the first and last name
  const initials = `${first_name.charAt(0).toUpperCase()}${last_name.charAt(0).toUpperCase()}`;

  return (
    <StyledCardHorizontal>
      <Grid container>
        {/* Left Side: Profile Picture and View All Details */}
        <Grid
          item
          xs={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* Avatar with fallback to initials */}
          <StyledAvatar src={image || ''} alt={`${first_name} ${last_name}`}>
            {!image && initials} {/* Display initials if no image is provided */}
          </StyledAvatar>
          <Typography
            variant="body2"
            color="primary.dark"
            sx={{
              cursor: 'pointer',
              fontFamily: 'Inter, sans-serif',
              textAlign: 'center',
              mt: 1,
            }}
          >
            View all details
          </Typography>
        </Grid>

        {/* Right Side: Supporter Information */}
        <Grid
          item
          xs={9}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ paddingX: 2 }}
        >
          <CardContent sx={{ padding: 0 }}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: 800,
                fontFamily: 'Inter, sans-serif',
              }}
            >
              {`${first_name} ${last_name}`}
            </Typography>

            <Box mt={2}>
              <SectionTitle variant="subtitle2">CONTACT INFORMATION</SectionTitle>
              <StyledDivider />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    {email}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    Phone:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    {phone_number || ''}
                  </Typography>
                </Grid>
                
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    Address:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    {address || ''}
                  </Typography>
                </Grid>

              </Grid>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </StyledCardHorizontal>
  );
};

export default SupporterCardHorizontal;
