// Google maps routes
export const getGoogleMapsKey = async () => {
    try {
        const response = await fetch(`/api/config/google_maps_key`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        });
    
        if (response.ok) {
        const data = await response.json();
        return data.key;
        } else {
        const errorData = await response.json();
        console.error('Failed to fetch Google Maps key:', errorData);
        return null;
        }
    } catch (error) {
        console.error('Error fetching Google Maps key:', error);
        return null;
    }
};

export const getGeoLocation = async (address: string) => {
    try {
        const response = await fetch(`/api/config/geocode?address=${encodeURIComponent(address)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        });

        if (response.ok) {
        const data = await response.json();
        return data;
        }

        const errorData = await response.json();
        console.error('Failed to fetch geolocation:', errorData);
        return null;
    } catch (error) {
        console.error('Error fetching geolocation:', error);
        return null;
    }

};