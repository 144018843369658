import React, {useState} from 'react';
import { Box, Button, Typography, Paper, Grid, Table, TableBody, TableCell, TableHead, TableRow, Collapse, Divider } from '@mui/material';
import CheckIcon from './icons/check.svg';  // Import SVG as React components if configured
import WarningIcon from './icons/warning.svg';
import XMarkIcon from './icons/xmark.svg';
import {
  IncompleteRecordsComponent,
  DuplicateRecordsComponent,
  UnsubscribedRecordsComponent,
  ExistingRecordsComponent,
  CleanRecordsComponent,
  SuspectedExistingRecordsComponent,
  UpdatingRecordsComponent
} from './CategoryDetailComponents'; // Import specific components

interface Category {
    icon: JSX.Element;
    title: string;
    component: React.ReactNode;
    description: string;
    color: string;
    textColor: string;
}

interface CategoryCount {
    [key: string]: number;
}

interface ReviewDataProps {
    onBack: (step: number) => void;
    reset: () => void;
    filename: string;
    counts: CategoryCount;
}

const ReviewData: React.FC<ReviewDataProps> = ({ onBack, filename, counts }) => {
  const [open, setOpen] = useState<string | null>(null);

  const handleToggle = (category: string) => {
      setOpen(open === category ? null : category);
  };

  // New function to close a category dropdown
  const handleCloseCategory = () => {
    setOpen(null);
  };

  const categories: Category[] = [
    {
      icon: <img src={XMarkIcon} alt="X Mark" style={{ width: 48, height: 48 }} />,
      title: 'Incomplete Records',
      component: <IncompleteRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'These records are missing one or more mandatory fields (First Name, Last Name, Email Address) and will not be imported.',
      color: '#7C90A3',
      textColor: '#33475B',
    },
    {
      icon: <img src={WarningIcon} alt="Warning" style={{ width: 48, height: 48 }} />,
      title: 'Suspected Duplicate Records',
      component: <DuplicateRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'Records in your file that have a matching email. Action is needed.',
      color: '#7C90A3',
      textColor: '#33475B',
    },
    {
      icon: <img src={WarningIcon} alt="Warning" style={{ width: 48, height: 48 }} />,
      title: 'Suspected Existing Records',
      component: <SuspectedExistingRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'Records that have an email that matches a record in the Dashboard, but other contact data may be different. Action is needed',
      color: '#7C90A3',
      textColor: '#33475B',
    },
    {
      icon: <img src={WarningIcon} alt="Warning" style={{ width: 48, height: 48 }} />,
      title: 'Unsubscribed Records',
      component: <UnsubscribedRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'These records have previously been marked as Unsubscribe in the Dashboard. No action is needed.',
      color: '#7C90A3',
      textColor: '#33475B',
    },
    {
      icon: <img src={CheckIcon} alt="Check" style={{ width: 48, height: 48 }} />,
      title: 'Updating Records',
      component: <UpdatingRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'Records that will update existing supporters in the Dashboard. No action is needed.',
      color: '#7C90A3',
      textColor: '#33475B',
    },
    {
      icon: <img src={CheckIcon} alt="Check" style={{ width: 48, height: 48 }} />,
      title: 'Existing Records',
      component: <ExistingRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'Records that match exactly with records in the Dashboard. No action is needed.',
      color: '#7C90A3',
      textColor: '#33475B',
    },
    {
      icon: <img src={CheckIcon} alt="Check" style={{ width: 48, height: 48 }} />,
      title: 'Clean Records',
      component: <CleanRecordsComponent onConfirm={handleCloseCategory}/>,
      description: 'No issues were found with these records.',
      color: '#7C90A3',
      textColor: '#33475B',
    },
  ];

  const formatDescription = (description: string): JSX.Element[] => {
    const boldPhrases: string[] = ["Action is needed", "will not be imported"];
    const regex = new RegExp(`(${boldPhrases.join('|')})`, 'gi');
    const parts: string[] = description.split(regex);
    
    return parts.map((part: string, index: number) => 
      boldPhrases.some(phrase => phrase === part) ? <strong key={index}>{part}</strong> : <span key={index}>{part}</span>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, m: 2 }}>
      <Typography variant="body1" gutterBottom style={{ color: '#33475B', marginBottom: '30px' }}>
        Please review the data for {filename}. Click “Details” to preview and fix any conflicts before importing.
      </Typography>
      <Grid container spacing={2}>
        {categories
          .filter((category) => counts[category.title] > 0)  // Filter out categories with 0 count
          .map((category) => (
            <Grid item xs={12} key={category.title}>
              <Paper elevation={0} sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                borderRadius: '5px',
                border: `2px solid ${category.color}`,
                backgroundColor: '#FFF'
              }}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {category.icon}
                    <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold', color: category.textColor, fontFamily: 'Inter' }}>
                        {counts[category.title] || 0} {category.title}
                      </Typography>
                      <Typography variant="body2" style={{ fontFamily: 'Inter' }}>
                        {formatDescription(category.description)}
                      </Typography>
                    </Box>
                  </Box>
                  <Button onClick={() => handleToggle(category.title)} sx={{
                    fontFamily: 'Inter',
                    fontWeight: 'bold',
                    textDecoration: 'underline', 
                    color: category.textColor, 
                    '&:hover': { backgroundColor: 'transparent' },
                    '& .MuiTouchRipple-root': {
                      color: 'transparent' // Get rid of that ripple effect from MUI
                    }
                  }}>
                    Details
                  </Button>
                </Box>
                <Collapse in={open === category.title} timeout="auto">
                  <Box sx={{ p: 2, width: '100%' }}>
                    <Divider sx={{ mt: 1 }} />  {/* Horizontal line as a divider */}
                    {category.component}
                  </Box>
                </Collapse>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Box>
  );  
};

export default ReviewData;
